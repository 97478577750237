import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath
  let header

  if (isRootPath) {
    header = (
      <div style={{ display: "flex" }}>
        <StaticImage
          className="bio-avatar"
          layout="fixed"
          formats={["auto", "webp", "avif"]}
          src="../images/profile-pic.png"
          width={100}
          height={100}
          quality={95}
          alt="Profile picture"
        />
        <div style={{ paddingTop: "0.7em" }}>
          <h1 className="main-heading">
            <Link to="/">{title}</Link>
          </h1>
          <p>
            {"Insta: "}
            <a
              href="https://www.instagram.com/milliedrewfood/"
              rel="noreferrer"
              target="_blank"
            >
              @milliedrewfood
            </a>
          </p>
        </div>
      </div>
    )
  } else {
    header = (
      <Link className="header-link-home" to="/">
        {title}
      </Link>
    )
  }

  return (
    <div className="global-wrapper" data-is-root-path={isRootPath}>
      <header className="global-header">{header}</header>
      <main>{children}</main>
      <footer>© Millie Drew {new Date().getFullYear()}</footer>
    </div>
  )
}

export default Layout
